import { Result } from "antd"
import { useTranslation } from "react-i18next"

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("Sorry, the page you visited does not exist.")}
    />
  )
}

export default PageNotFound
